@import './assets/scss/style.scss';

$base-color: #17102f;
$base-light: #41cf90;
$light-secondary: #e6e6ee;
$font-regular: 'CircularRegular', sans-serif;
$font-medium: 'CircularMedium', sans-serif;
$font-bold: 'CircularBold', sans-serif;
$danger-color: #c70a12;
$hot-color: #ff4950;
$secondary-color: #3e3561;
$third-color: #5a546e;
$dark-grey: #70818b;
@font-face {
  font-family: 'CircularRegular';
  src: url('../fonts/CircularStd-Book.ttf') format('truetype'),
    url('../fonts/CircularStd-Book.eot') format('eot');
}
@font-face {
  font-family: 'CircularBold';
  src: url('../fonts/CircularStd-Bold.eot') format('eot'),
    url('../fonts/CircularStd-Bold.ttf') format('truetype');
}
@font-face {
  font-family: 'CircularMedium';
  src: url('../fonts/CircularStd-Medium.eot') format('eot'),
    url('../fonts/CircularStd-Medium.ttf') format('truetype');
}
.text--color {
  color: $base-light;
}
.bold--text {
  font-weight: bold;
  font-family: $font-bold;
}
.medium--text {
  font-family: $font-medium;
  font-weight: 500;
}
.bord--color {
  border-color: $base-light;
  transition: 0.4s ease background-color, border-color;
}
.bord--color:hover {
  background-color: $base-light;
  color: #fff;
}
.back--color {
  background-color: $base-light;
}
.bord--info {
  border: 4px solid rgba(151, 151, 151, 0.5);
}
body * {
  font-family: $font-regular, sans-serif;
  font-weight: 400;
  color: $base-color;
}
.hero__block {
  border-bottom: 1px solid $light-secondary;
}
.join__block {
  background-color: #f4f4f7;
  & .join__features {
    & .feature__info {
      max-width: 435px;
    }
  }
  & .features__list {
    & .elem__feature {
      border-right: 1px solid rgba(0, 0, 0, 0.07);
      &:nth-child(3n) {
        border-right: 0px;
      }
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6) {
        border-bottom: 0px;
      }
    }
  }
}
.info__block {
  background-color: #f4f4f7;
}
.testimonials__block {
  background-color: #e6e5ed;
  & .slick-prev {
    min-width: 10px;
    min-height: 17px;
    background: url('../img/leftarr.svg') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
  }
  & .slick-next {
    min-width: 10px;
    min-height: 17px;
    background: url('../img/rightarr.svg') !important;
    background-repeat: no-repeat !important;
    background-position: center !important;
    background-size: contain !important;
  }
  & .slick-prev:before {
    content: none;
  }
  & .slick-next:before {
    content: none;
  }
  & .testimon__wrapper {
    max-width: 640px;
    & .person__image {
      & img {
        max-width: 52px;
      }
    }
  }
}
.sign__block {
  background: url('../img/readyback.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
footer {
  & p {
    color: #a098b2;
  }
}
.global__block {
  border-bottom: 1px solid #e5e5e5;
  & .outer__global {
    & .global__features {
      & .elem__global--feature {
        &:last-child {
          border-right: 0px;
          &:before {
            content: none;
          }
        }
        &:before {
          content: '';
          position: absolute;
          right: -32px;
          top: 0px;
          width: 1px;
          height: 100%;
          background-color: rgba(19, 19, 19, 0.13);
        }
      }
    }
    & .global__inner {
      & .global__image {
        background-color: $light-secondary;
      }
    }
  }
}

.platform__block {
  background-color: #f4f4f7;
  & .outer__platform {
    & .platform__info {
      max-width: 480px;
    }
    .learn__info--text {
      color: $base-light;
    }
  }
}

.trusted__big {
  border-top: 1px solid $light-secondary;
}
.elem__small--feedback {
  .feedback__author {
    .author__image {
      img {
        max-width: 70px;
      }
    }
    .author__details {
      > span {
        color: #6e6883;
      }
      max-width: 205px;
    }
  }
}
.small__feedback {
  .inner__small--feedback {
    max-width: 740px;
  }
}
.scale__block {
  .outer__scale {
    max-width: 1020px;
  }
  .scale__details {
    max-width: 460px;
  }
  border-top: 1px solid $light-secondary;
}
.point__span {
  min-width: 17px;
  position: relative;
  top: 4px;
}
.outer__enterprise {
  max-width: 540px;
}
.enterprise__grid {
  max-width: 380px;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
}
.form__block {
  background-color: #f6f8fb;
}
.form__wrapper.create__loop {
  max-width: 675px;
}
.form__wrapper {
  max-width: 555px;
  width: 100%;
  box-shadow: 0 7px 10px rgba(0, 0, 0, 0.05);
}
.text--dark {
  color: #797888;
}
.input--border {
  border: 1px solid #a6a6a6;
}
.group__submit {
  input {
    font-family: $font-medium;
    font-weight: 500;
  }
}
.or__head {
  > p {
    color: $secondary-color;
    opacity: 0.5;
  }
  > span {
    height: 1px;
    background-color: #979797;
  }
}
.element__or {
  > a {
    border: 1px solid rgba(166, 166, 166, 0.35);
  }
}
.code__form {
  max-width: 320px;
}
.head__form {
  > h2 {
    color: #332857;
  }
  > p {
    color: $secondary-color;
  }
}
.workspace__form {
  max-width: 340px;
}
.choose__workspace {
  max-width: 345px;
}
.workspace__elem {
  background-color: #eaeef5;
  .workspace__info {
    .workspace__image {
      img {
        max-width: 34px;
      }
    }
  }
}

.form-group {
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
    &:checked {
      + {
        label {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: auto;
            left: 7px;
            margin-top: -1px;
            width: 5px;
            height: 12px;
            border: solid $base-light;
            border-width: 0 2px 2px 0;
            transform: rotate(45deg);
          }
        }
      }
    }
  }
  label {
    span {
      position: relative;
      top: 2px;
      color: $third-color;
    }
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 14px;
    &:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid $base-light;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 0px;
      width: 18px;
      height: 18px;
      max-width: 18px;
      max-height: 18px;
      min-height: 18px;
      min-width: 18px;
      border-radius: 2px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      top: 0px;
      margin-right: 5px;
    }
  }
}
.radio-group {
  input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
    &:checked {
      + {
        label {
          &:after {
            content: '';
            display: block;
            position: absolute;
            top: 2px;
            left: 0px;
            min-width: 18px;
            max-width: 18px;
            min-height: 18px;
            max-height: 18px;
            background: url('../img/radiocheck.svg');
            background-repeat: no-repeat;
            border-radius: 250px;
            background-size: 9px;
            background-position: center;
            background-color: #32bea6;
          }
        }
      }
    }
  }
  label {
    span {
      position: relative;
      top: 2px;
      color: $third-color;
    }
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    font-size: 14px;
    &:before {
      content: '';
      -webkit-appearance: none;
      background-color: transparent;
      border: 1px solid $base-light;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
        inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
      padding: 0px;
      width: 18px;
      height: 18px;
      border-radius: 250px;
      max-width: 18px;
      max-height: 18px;
      min-height: 18px;
      min-width: 18px;
      display: inline-block;
      position: relative;
      vertical-align: middle;
      cursor: pointer;
      top: 2px;
      margin-right: 5px;
    }
  }
}

.error__group {
  p {
    color: $secondary-color;
    span {
      min-width: 19px;
    }
  }
  border: 1px solid $danger-color;
  background-color: #fff1f2;
}
.error__input {
  > p {
    color: $danger-color;
  }
  > input {
    border-color: $danger-color;
  }
}

.pricing__wrapper {
  background-color: #f4f8fb;
  & .outer__pricing--info {
    max-width: 940px;
  }
}
.picker__pricing.inner__pricing--switcher {
  > .elem__pricing {
    > .head__pricing {
      > p {
        color: #828f9e;
      }
    }
  }
}
.pricing__info.pricing__light {
  .inner__pricing--switcher {
    > .elem__pricing.recommended__pricing {
      background-color: transparent;
    }
    > .elem__pricing {
      > .head__pricing {
        > h5 {
          color: $base-color;
        }
        > p {
          color: $third-color;
        }
      }
    }
  }
}
.inner__pricing--switcher {
  > .elem__pricing {
    .pricing__features {
      ul {
        li {
          color: $third-color;
          span {
            min-width: 11px;
          }
        }
      }
      border-top: 1px solid $light-secondary;
    }
    > .head__pricing {
      > p {
        min-height: 42px;
      }
      > h6 {
        color: $base-color;
      }
      > h5 {
        color: #797688;
      }
    }
    border: 1px solid $light-secondary;
  }
  > .elem__pricing.recommended__pricing {
    background-color: #fff;
    border-color: $base-light;
  }
}
.offer__pricing {
  background: url('../img/offerback.svg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  right: 10px;
  top: 10px;
}
header {
  & .logo__wrapper {
    img {
      max-width: 86px;
    }
  }
  .header__left {
    img {
      max-width: 86px;
    }
  }
  &.colored__header {
    border-bottom: 1px solid #dae0ec;
  }
  & .search__box {
    position: absolute;
    left: 0px;
    top: 63px;
    width: 100%;
    height: calc(100% - 63px);
    background-color: rgba(0, 0, 0, 0.69);
    z-index: 11;
    & .inner__search--box {
      width: 100%;
      padding: 25px 0px;
      background-color: #ffffff;
      & .search__results--inner {
        & .search__inner--header {
          & > p {
            margin: 0px;
            color: #545454;
            font-weight: 400;
          }
        }
      }
    }
  }
  &.colored__header {
    background-color: #e8ecf3;
  }
  & .outer__header {
    & .header__right {
      & .notification__icon {
        & > a {
          min-width: 39px;
          min-height: 38px;
          max-width: 39px;
          border: 1px solid #9aadd0;
          max-height: 34px;
        }
      }
      & .profile__header {
        & .profile__image {
          & img {
            min-width: 38px;
            max-width: 38px;
          }
        }
      }
      & .points__header {
        & > a {
          background-color: #d3ddef;
          & p {
            & > span {
              & img {
                min-width: 12px;
              }
            }
          }
          & > span {
            font-family: $font-medium;
            font-weight: 500;
          }
        }
      }
    }
  }
  & .search__header {
    width: 100%;
    max-width: 500px;
    & input[type='text'] {
      background-color: #f6f8fb;
    }
    & input[type='submit'] {
      right: 15px;
      min-width: 14px;
      min-height: 14px;
      max-width: 14px;
      max-height: 14px;
      background: url('../img/searchicon.svg');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center;
    }
  }
}
body.loop__back {
  background-color: #edecf2;
}

.loop__main {
  .loop__table {
    min-width: 300px;
    background-color: #d4dbe4;
  }
  .loop__wrapper {
    .loop__video {
      overflow: hidden;
      padding-top: 56.25%;
      position: relative;
      iframe {
        border: 0;
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }
  }
  .loop__tags {
    > ul {
      background-color: #d7d5e0;
    }
  }
}
.loop__info {
  .loop__date {
    > p {
      color: $third-color;
    }
  }
  .loop__head {
    border-bottom: 1px solid #dcdcdc;
  }
}
.hot__loop {
  background-color: $hot-color;
}
.loop__inner--stat {
  .elem__stat {
    p {
      color: #8c9aa7;
    }
  }
}
.loop__description {
  border-bottom: 1px solid #dcdcdc;
  > h6 {
    font-family: $font-medium;
    font-weight: 500;
  }
}
.loop__materials {
  border-bottom: 1px solid #dcdcdc;
  > h6 {
    font-family: $font-medium;
    font-weight: 500;
  }
}

.elem__material {
  > a {
    > span {
      color: #757a81;
    }
  }
  .material__image {
    img {
      max-width: 36px;
      min-width: 36px;
    }
  }
}
.comment__form {
  textarea {
    border: 1px solid #6d6d6d;
    background-color: transparent;
  }
}
.comments__list {
  .elem__comment {
    .comment__info {
      > p {
        color: $third-color;
      }
    }
    background-color: #e3e3ed;
  }
}
.comment__field {
  .profile__comment {
    min-width: 35px;
    max-width: 35px;
    min-height: 35px;
    max-height: 35px;
  }
}
.loop__table {
  > .table__header {
    border-bottom: 1px solid #b9cae1;
  }
}
.loop__list--info {
  > span {
    color: #6c89a9;
  }
}
.table__loop--list {
  > .elem__loop--list {
    border-bottom: 1px solid #b9cae1;
  }
}
.loop__list--status {
  min-width: 15px;
  max-width: 15px;
}
.loop__video {
  .edit__button {
    > a {
      background-color: rgba(0, 0, 0, 0.65);
      color: rgba(255, 255, 255, 0.71);
    }
    position: absolute;
    top: 10px;
    right: 15px;
  }
}
.notification__drop {
  .notification__head {
    > p {
      color: #7c8897;
    }
    border-bottom: 1px solid $light-secondary;
  }
  position: absolute;
  top: 50px;
  right: -80px;
  min-width: 310px;
  max-width: 310px;
  z-index: 19;
  display: none;
  box-shadow: 0 6px 27px rgba(0, 0, 0, 0.13);
  &:before {
    content: '';
    position: absolute;
    left: auto;
    right: 94px;
    min-width: 12px;
    min-height: 7px;
    background: url('../img/arrownotification.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    top: -7px;
  }
  .notification__list {
    overflow-y: auto;
    max-height: 380px;
  }
}
.notification__icon {
  position: relative;
}
.notification__list {
  .elem__notification {
    border-bottom: 1px solid $light-secondary;
    &:last-child {
      border-bottom: 0px;
    }
    .notification__person.important__notification {
      &:before {
        content: '';
        position: absolute;
        min-width: 6px;
        max-width: 6px;
        min-height: 6px;
        max-height: 6px;
        background-color: $hot-color;
        border-radius: 250px;
        top: -2px;
        left: -6px;
      }
    }
    .notification__person {
      min-width: 35px;
      max-width: 35px;
    }
  }
}

.settings__back {
  background-color: #edf1f4;
}
.settings__head {
  border-bottom: 1px solid $light-secondary;
  & .outer__settings {
    max-width: 960px;
    & ul {
      & li {
        &.current__settings {
          & a {
            color: $base-color;
            &:before {
              width: calc(100% + 30px);
            }
          }
        }
        & a {
          color: #6a737d;
          transition: 0.4s ease all;
          &:before {
            content: '';
            position: absolute;
            right: 0px;
            margin-left: auto;
            margin-right: auto;
            bottom: -16px;
            width: calc(100% + 30px);
            left: -15px;
            height: 2px;
            width: 0px;
            background-color: $base-light;
            transition: 0.25s ease all;
          }
          &:hover {
            color: $base-color;
            &:before {
              width: calc(100% + 30px);
            }
          }
        }
      }
    }
  }
}
.settings__content {
  max-width: 960px;
}
.top__plan {
  .plan__info {
    > p {
      color: $third-color;
    }
    > span {
      color: $base-color;
    }
  }
  border-bottom: 1px solid $light-secondary;
  .plan__info--elem {
    min-width: 240px;
  }
}
.plan__inner {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.19);
}
.bottom__plan {
  .bottom__info--plan {
    p {
      color: $third-color;
    }
  }
}
.pricing__settings {
  .elem__pricing {
    padding-bottom: 95px;
    .pricing__button {
      bottom: 30px;
    }
  }
  .pricing__button {
    a {
      max-width: 280px;
    }
  }
}
.account__status {
  > div {
    p {
      color: $third-color;
    }
    border: 1px solid $light-secondary;
  }
}
.elem__member {
  .member__profile {
    .member__info {
      > p {
        color: $third-color;
      }
      > span {
        color: $third-color;
      }
    }
    .member__icon {
      img {
        min-width: 40px;
        max-width: 40px;
      }
    }
  }
  > div.member__profile {
    min-width: 250px;
    max-width: 250px;
  }
  > div.member__info {
    min-width: 100px;
    max-width: 100px;
  }
  > div.member__status {
    min-width: 95px;
    max-width: 95px;
  }
  > div.member__auth {
    min-width: 110px;
    max-width: 110px;
  }
  > div.member__controls {
    min-width: 40px;
    max-width: 40px;
  }
  border-bottom: 1px solid $light-secondary;
}
.member__status {
  .inactive__status {
    background-color: #ff3d48;
    min-width: 8px;
    max-width: 8px;
    min-height: 8px;
    max-height: 8px;
  }
  .active__status {
    min-width: 8px;
    max-width: 8px;
    min-height: 8px;
    max-height: 8px;
    background-color: $base-light;
  }
}
.member__controls {
  a {
    &:hover {
      border-color: $light-secondary;
    }
  }
  > a.current__controls {
    border-color: $light-secondary;
    img {
      filter: invert(64%) sepia(62%) saturate(393%) hue-rotate(101deg)
        brightness(94%) contrast(96%);
    }
  }
  > a {
    &:hover {
      > img {
        filter: invert(64%) sepia(62%) saturate(393%) hue-rotate(101deg)
          brightness(94%) contrast(96%);
      }
    }
    min-width: 30px;
    max-width: 30px;
    min-height: 30px;
    max-height: 30px;
    border: 1px solid transparent;
  }
  .controls__dropdown {
    top: 40px;
    min-width: 210px;
    max-width: 210px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.27);
    z-index: 5;
  }
}
.member__auth {
  > p {
    color: $third-color;
  }
}
.member__info {
  > p {
    color: $third-color;
  }
}
.content__members {
  > .title__members {
    > p {
      color: #7c8897;
    }
    border-bottom: 1px solid $light-secondary;
  }
}
.controls__dropdown {
  ul {
    li {
      a {
        span {
          min-width: 20px;
          max-width: 20px;
        }
        &:hover {
          > span {
            > img {
              &:nth-child(1) {
                display: none;
              }
              &:nth-child(2) {
                display: block;
              }
            }
          }
          background-color: $base-light;
          color: #fff;
        }
        > span {
          > img {
            &:nth-child(2) {
              display: none;
            }
          }
        }
      }
    }
  }
}
.title__members {
  > p {
    &:nth-child(1) {
      min-width: 250px;
      max-width: 250px;
    }
    &:nth-child(2) {
      min-width: 100px;
      max-width: 100px;
    }
    &:nth-child(3) {
      min-width: 95px;
      max-width: 95px;
    }
    &:nth-child(4) {
      min-width: 110px;
      max-width: 110px;
    }
    &:nth-child(5) {
      min-width: 40px;
      max-width: 40px;
    }
  }
}
.payment__method {
  max-width: 720px;
}
.inner__change--payment {
  border: 1px solid $light-secondary;
}
.elem__card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14);
  .content__card {
    p {
      color: #bbbbc7;
    }
  }
}
.settings__content {
  & .settings__profile {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  & .settings__profile--picture {
    max-width: 180px;
    margin-left: 60px;
    min-width: 180px;
    & .profile__picker {
      & img {
        width: 80px;
        min-width: 80px;
        max-width: 80px;
        border-radius: 250px;
      }
    }
    & > p {
      color: #93a0ae;
    }
    & input[type='file'] {
      left: -3000px;
    }
  }
}
.relative__group {
  position: relative;
  top: 5px;
}

.area__profile {
  height: 99px;
}
.search__header {
  > form {
    width: 100%;
  }
}
.settings__picker {
  > p {
    color: $third-color;
  }
}
.load__image {
  > input[type='file'] {
    position: absolute;
    left: -3000px;
  }
  a {
    span {
      img {
        width: 21px;
      }
    }
    p {
      color: $third-color;
    }
    border: 1px dashed #bbbbc7;
  }
}
.tips__load {
  ul {
    li {
      color: $third-color;
    }
  }
}
.settings__elem {
  .note__elem {
    > p {
      color: $third-color;
    }
    > h6 {
      color: $base-color;
    }
  }
}
.delete__knowledge {
  > input {
    background-color: #ff484f;
    color: #fff;
  }
}
.camera__block {
  .outer__camera {
    max-width: 1040px;
  }
}
.camera--main {
  position: relative;
  height: 0;

  video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  > span {
    left: 20px;
    top: 20px;
    color: $hot-color;
    background-color: rgba(0, 0, 0, 0.47);
  }
}
.camera__controls {
  background-color: #ececf2;

  > button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 48px;
    max-width: 48px;
    min-height: 48px;
    max-height: 48px;
    border-radius: 250px;
    background-color: #d7d6e4;

    position: relative;

    & > .name {
      position: absolute;

      left: 0;
      right: 0;
      margin: 0 auto;

      top: initial;
      bottom: -20px;

      display: flex;
      justify-content: center;

      white-space: nowrap;

      font-size: 12px;

      @media only screen and (max-width: 768px) {
        & {
          display: none;
        }
      }
    }
  }
}
.turn__camera {
  > span {
    position: absolute;
    top: -0px;
    right: -0px;
  }
}
.toggle__button.disabled__toggle {
  &:before {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotate(20deg);
    margin-left: auto;
    margin-right: auto;
    width: 22px;
    height: 3px;
    background-color: $hot-color;
  }
}
.turn__camera.active__camera {
  > span {
    display: none;
  }
}
.step__block {
  .outer__step {
    .step__head {
      border-bottom: 1px solid $light-secondary;
    }
    .controls__step {
      border-top: 1px solid $light-secondary;
    }
    max-width: 770px;
    box-shadow: 0 7px 14px rgba(0, 0, 0, 0.05);
  }
}
.uploaded__material {
  .material__inner {
    > a {
      position: absolute;
      right: 7px;
      top: 7px;
    }
    background-color: #f3f3f3;
    > p {
      color: $third-color;
    }
    > span {
      color: #757a81;
    }
  }
}
.inner__supporting {
  .supporting__button {
    > a {
      border: 2px dashed #aaa9bf;
      height: 100%;
    }
  }
}
.elem__thumb.active__thumb {
  > span {
    display: block;
    top: 4px;
    right: 4px;
  }
}
.elem__thumb {
  > span {
    display: none;
  }
}
.supporting__material {
  .inner__supporting {
    margin-top: -11px;
    margin-left: -11px;
  }
  input[type='file'] {
    position: absolute;
    left: -3000px;
  }
}
.supporting__button {
  > a {
    > span {
      > img {
        opacity: 0.7;
      }
    }
    > p {
      color: #bbbbc7;
    }
  }
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}
select {
  background: url('../img/arrowselect.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 95% center;
}
.form__light {
  .outer__form--light {
    max-width: 425px;
  }
  .outer__form--light.create__workspace {
    .workspace__form {
      max-width: 425px;
    }
    max-width: 600px;
  }
}
.already__reg {
  color: #443c66;
}

body.light__back {
  background-color: #f6f8fb;
}
.outer__free {
  > form {
    > p {
      color: $secondary-color;
    }
    > h2 {
      color: #332857;
    }
  }
}
.tip__group {
  color: #8c8c8c;
}
.form__light {
  .head__form {
    > p {
      color: #6e6883;
    }
  }
}
.video__container {
  min-height: calc(100vh - 65px);
  height: auto;
  .video__side {
    min-width: 280px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.07);
  }
  .channels__video {
    > .head__channels {
      > p {
        color: $dark-grey;
      }
    }
  }
  .video__title {
    p {
      img {
        max-width: 38px;
        min-width: 38px;
        max-width: 38px;
        min-height: 38px;
      }
    }
  }
}
.add__channel {
  > a {
    color: $dark-grey;
  }
}
.channel__list {
  .elem__channel {
    p {
      span {
        position: relative;
        top: -1px;
      }
    }
    > span {
      position: absolute;
      right: 0px;
      min-width: 25px;
      min-height: 17px;
      max-height: 17px;
      background-color: $base-light;
      border-radius: 8.5px;
    }
  }
}
.member__list {
  .list__member {
    .member__block {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      &:hover {
        opacity: 1;
      }
    }
  }
  > .head__members {
    > p {
      color: $dark-grey;
    }
  }
}
.list__member {
  .member__block {
    > p {
      color: $dark-grey;
    }
    .member__profile {
      img {
        min-width: 23px;
        max-width: 23px;
      }
    }
  }
}
.video__main {
  .head__video--main {
    > a {
      color: $third-color;
    }
    .sub__channel {
      > a {
        color: $third-color;
      }
    }
  }
}
.video__list--container {
  .video__list {
    .head__video--list {
      h2 {
        color: $base-color;
      }
    }
  }
  margin-top: -32px;
}
.elem__video--list {
  .list__image {
    span {
      position: absolute;
      right: 7px;
      bottom: 7px;
      background-color: rgba(0, 0, 0, 0.71);
    }
    height: 145px;
  }
  > .new__span {
    display: none;
  }
}
.video__list--description {
  > a {
    position: absolute;
    right: 0px;
  }
  ul {
    li {
      color: $third-color;
    }
  }
}
.video__list--wrap {
  grid-template-columns: minmax(140px, 260px);
}
.modal__block {
  background-color: rgba(0, 0, 0, 0.8);
  height: 100%;
  z-index: 20;
}
.modal__wrapper {
  .close__modal {
    position: absolute;
    right: 15px;
    top: 20px;
    min-width: 18px;
    max-width: 18px;
    min-height: 18px;
    max-height: 18px;
  }
  .welcome__info {
    > h6 {
      color: $base-color;
    }
  }
}
.elem__video--list.new__video {
  > .new__span {
    display: block;
    position: absolute;
    background-color: $hot-color;
    z-index: 2;
    top: 10px;
    right: 10px;
  }
}
.modal__wrapper.welcome__modal {
  max-width: 525px;
  background: url('../img/joinback.png');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top center;
  background-color: #fff;
}
.notification__icon {
  .notification__drop {
    > .notification__head {
      > a {
        display: none;
      }
    }
  }
  > a {
    > img {
      &:nth-child(2) {
        display: none;
      }
    }
  }
  > .notification__number {
    display: none;
  }
}
.notification__icon.notification__new {
  .notification__drop {
    > .notification__head {
      > a {
        display: inline-flex;
        font-size: 12px;
        font-weight: 400;
        color: $base-light;
        text-decoration: none;
        transition: 0.4s ease all;
        &:hover {
          opacity: 0.7;
        }
      }
    }
  }
  > a {
    > img {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: block;
      }
    }
    background-color: $base-light;
    border-color: $base-light !important;
  }
  > .notification__number {
    > span {
      font-size: 10px;
      color: #fff;
    }
    position: absolute;
    right: -6px;
    top: -4px;
    z-index: 3;
    display: inline-flex;
    border-radius: 7px;
    background-color: $hot-color;
    padding: 1px 4px;
    height: 14px;
  }
}
.header__right {
  .points__header {
    > a {
      padding-top: 0px;
      padding-bottom: 0px;
      min-height: 38px;
    }
  }
}
.search__header {
  form {
    .search__field {
      > input {
        height: 34px;
      }
    }
  }
}
.channel__settings {
  .channel__dropdown {
    min-width: 210px;
    max-width: 210px;
    position: absolute;
    left: 0px;
    z-index: 5;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.21);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 33px;
  height: 17px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $light-secondary;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  &:before {
    position: absolute;
    content: '';
    height: 17px;
    width: 17px;
    left: 0px;
    bottom: 0px;
    background-color: #9a9a9a;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
}
.channel__dropdown {
  > p {
    color: #93a0ae;
  }
}
input {
  &:checked {
    + {
      .slider {
        background-color: $light-secondary;
        &:before {
          background-color: $base-light;
          -webkit-transform: translateX(17px);
          -ms-transform: translateX(17px);
          transform: translateX(17px);
        }
      }
    }
  }
}
.slider.round {
  border-radius: 34px;
  &:before {
    border-radius: 50%;
  }
}

.channel__settings {
  > a {
    > img {
      &:nth-child(1) {
        display: block;
      }
      &:nth-child(2) {
        display: none;
      }
    }
  }
  > a.active__settings {
    > img {
      &:nth-child(1) {
        display: none;
      }
      &:nth-child(2) {
        display: block;
      }
    }
  }
}
.modal__wrapper.sub__modal {
  max-width: 470px;
}
.wrap__hash {
  > span {
    position: absolute;
    left: 15px;
    color: #bbbbc7;
  }
}
.sub__modal {
  .sub__controls {
    border-top: 1px solid $light-secondary;
  }
}
.modal__block {
  .modal__wrapper {
    box-shadow: 0 22px 22px rgba(22, 22, 28, 0.37);
  }
  .members__list {
    max-width: 400px;
  }
}
.member__copy--link {
  > p {
    color: $third-color;
  }
  width: calc(100% + 50px);
  margin-left: -25px;
  border-top: 1px solid $light-secondary;
}
.member__list--container {
  max-height: 150px;
  overflow-y: auto;
}

body *::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

body *::-webkit-scrollbar-track {
  background: transparent;
}

body *::-webkit-scrollbar-thumb {
  background: #e2e2ef;
  border-radius: 5px;
}

.el__member {
  .member__info {
    > span {
      color: $third-color;
    }
    > h6 {
      color: $base-color;
    }
  }
  .member__image {
    img {
      min-width: 35px;
      max-width: 35px;
      min-height: 35px;
      max-height: 35px;
    }
  }
}
.modal__block {
  .step__modal.modal__wrapper {
    max-width: 770px;
  }
  .modal__wrapper.step__modal {
    max-height: calc(100% - 80px);
    overflow-y: auto;
  }
  .picker__pricing {
    .elem__pricing.current__pricing {
      .head__pricing {
        > h5 {
          color: $base-light;
        }
      }
    }
  }
  .invite__button {
    width: calc(100% + 45px);
    margin-left: -22.5px;
    padding-right: 22.5px;
    border-top: 1px solid $light-secondary;
  }
  .modal__wrapper.delete__modal {
    max-width: 525px;
    p {
      max-width: 340px;
      margin-left: auto;
      color: $third-color;
      margin-right: auto;
    }
  }
  .modal__wrapper.video__added {
    max-width: 470px;
    max-height: calc(100% - 50px);
    overflow-y: auto;
  }
}
.step__modal {
  .step__head {
    border-bottom: 1px solid $light-secondary;
  }
  .controls__step {
    border-top: 1px solid $light-secondary;
  }
}
.video__container {
  .outer__settings {
    max-width: 1240px;
  }
  .settings__content {
    max-width: 1240px;
  }
}
.pricing__settings.video__pricing {
  .inner__pricing--switcher {
    > .elem__pricing {
      &:nth-child(1) {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-right: 0px;
        position: relative;
        &:before {
          content: '';
          position: absolute;
          height: calc(100% - 50px);
          top: 25px;
          left: auto;
          right: 0px;
          width: 1px;
          background-color: $light-secondary;
        }
      }
      &:nth-child(3) {
        &:before {
          content: '';
          position: absolute;
          height: calc(100% - 50px);
          top: 25px;
          right: auto;
          left: 0px;
          width: 1px;
          background-color: $light-secondary;
        }
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-left: 0px;
      }
      &:nth-child(2) {
        border-left: 0px;
        border-right: 0px;
        border-radius: 0px;
      }
    }
  }
}
.picker__pricing {
  .elem__pricing.current__pricing {
    background-color: #fff;
    border: 1px solid #17a088;
  }
  .elem__pricing {
    cursor: pointer;
  }
}
.modal__wrapper.paym__info--wrapper {
  max-width: 720px;
  max-height: calc(100% - 80px);
  overflow-y: auto;
}
.modal__wrapper.pick__price--modal {
  max-width: 1040px;
  max-height: calc(100% - 80px);
  overflow-y: auto;
}
.modal__wrapper.new__channel {
  max-width: 450px;
  max-height: calc(100% - 50px);
  overflow-y: auto;
}
.billed__info--block {
  background-color: #f3f3fa;
  .billed__container {
    .billed__details {
      > p {
        color: $base-color;
      }
    }
    > span {
      position: relative;
      top: 3px;
    }
  }
}
.modal__wrapper.channel_v_sub {
  max-width: 450px;
  max-height: calc(100% - 50px);
  overflow-y: auto;
}
.sub__channel--add {
  .controls__sub--channel {
    > p {
      color: $third-color;
    }
  }
}
.opened__channel--video {
  > .head__opened {
    > span {
      color: $third-color;
    }
  }
}
.empty__list {
  border: 1px solid $light-secondary;
  border-radius: 6px;
  background: url('../img/emptylist.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: center;
}
.new__video {
  position: relative;
  &:hover {
    &:before {
      opacity: 0.7;
    }
  }
  &:before {
    content: 'NEW';
    color: #fff;
    font-size: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    font-family: $font-bold;
    font-weight: bold;
    background-color: $hot-color;
    border-radius: 3px;
    transition: 0.4s ease all;
    padding: 1px 7px;
    right: 10px;
    padding-top: 3px;
    z-index: 3;
    top: 10px;
  }
}
.recent__opened {
  > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 20px;
    top: 15px;
    transition: 0.4s ease all;
    &:hover {
      opacity: 0.7;
    }
  }
  .content__recent {
    .content__recent--elem {
      .content__recent--info {
        > span {
          color: #928caa;
        }
      }
    }
  }
  .head__recent {
    border-bottom: 1px solid #d8d7e3;
    > p {
      color: $third-color;
    }
  }
  background-color: #e5eaee;
}
.video__list--description {
  > a {
    border-radius: 250px;
    transition: 0.4s ease all;
    min-width: 24px;
    max-width: 24px;
    min-height: 24px;
    max-height: 24px;
    transition: 0.4s ease all;
    border: 1px solid #cfcfd1;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }
  > a.bookmarked {
    background-color: $hot-color;
    border-color: $hot-color;
    img {
      filter: invert(0%) sepia(100%) saturate(2%) hue-rotate(211deg)
        brightness(108%) contrast(101%);
    }
  }
}
.multiple__overlay {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
  z-index: 5;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  max-width: 45%;
  min-width: 115px;
  background-color: rgba(0, 0, 0, 0.7);
}
.elem__video--list {
  .multiple__overlay {
    > span {
      position: static;
    }
  }
}
.bookmarked__list {
  padding-bottom: 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid $light-secondary;
}
.video__list--container {
  .opened__container {
    display: none;
  }
}
.video__list--wrap.triple__video--list {
  grid-template-columns: repeat(3, minmax(140px, 260px));
}
.video__list.list__outlined {
  > .head__video--list {
    background-color: #e5eaee;
  }
  border: 1px solid $light-secondary;
}
.elem__comment {
  .comment__profile {
    img {
      min-width: 38px;
      max-width: 38px;
    }
  }
}
.small__table--button {
  > a {
    > span {
      &:last-child {
        margin-bottom: 0px;
      }
      position: relative;
      top: 0px;
      min-width: 27px;
      min-height: 3px;
      border-radius: 5px;
      background-color: $base-color;
      margin-bottom: 5px;
      transition: 0.4s ease all;
    }
  }
}
.loop__main {
  > .overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: none;
  }
}
.star__dropdown {
  min-width: 250px;
  max-width: 250px;
  left: 50%;
  transform: translate(-35%, 0);
  top: 30px;
  box-shadow: 0 6px 27px rgba(0, 0, 0, 0.13);
  &:before {
    content: '';
    position: absolute;
    top: -8px;
    width: 18px;
    height: 8px;
    left: 32%;
    background: url('../img/arrownotification.png');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: center;
  }
  .author__image {
    img {
      min-width: 35px !important;
      min-height: 35px !important;
      max-height: 35px !important;
      object-fit: cover;
      border-radius: 250px;
      max-width: 35px !important;
    }
  }
}
.star__block {
  .about__author {
    .auth__info--elem {
      > p {
        color: $base-color;
      }
      > h6 {
        color: $third-color;
      }
    }
  }
  > a {
    cursor: pointer;
  }
}
.updated__info {
  background-color: #f4f8fb;
}
.watched__inner {
  .elem__watched {
    padding: 7.5px;
  }
  .elem__watched--more {
    padding: 7.5px;
    padding: 7.5px 15px;
    .watched__number {
      > span {
        color: $base-light;
      }
      min-width: 46px;
      max-width: 46px;
      min-height: 46px;
      max-height: 46px;
      border-radius: 250px;
      background-color: rgba(65, 207, 144, 0.18);
    }
  }
}
.elem__watched--more {
  p {
    color: $base-light;
  }
}
.elem__watched {
  .watched__image {
    img {
      min-width: 46px;
      max-width: 46px;
      border-radius: 250px;
    }
  }
}
.loop__date {
  .author__loop {
    > p {
      color: $third-color;
    }
  }
  .date__loop {
    > p {
      color: $third-color;
    }
  }
}
.loop__description {
  > p {
    color: $third-color;
  }
}
.elem__material {
  > a {
    > p {
      color: $base-color;
    }
  }
}
.loop__info.customer__info {
  position: relative;
  .loop__stat {
    .loop__inner--stat {
      padding-right: 0px;
    }
  }
}
.loop__info {
  .customer__created {
    > span {
      display: inline-flex;
      color: $third-color;
    }
  }
}
.created__info {
  .bottom__created {
    p {
      color: $third-color;
    }
  }
  .top__created {
    > p {
      color: $third-color;
    }
  }
  .stars__created {
    border-radius: 250px;
  }
}
.loop__status {
  > span {
    display: inline-flex;
    min-width: 20px;
    min-height: 1px;
    background-color: #91a7cc;
    transition: 0.3s ease all;
  }
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(0%, -50%);
}
.elem__loop--list {
  position: relative;
  .remove__loop {
    > a {
      transition: 0.3s ease all;
    }
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.loop__list--info {
  > p {
    margin-bottom: 0px;
  }
}
.draggable__loop--list {
  .elem__loop--list {
    &:hover {
      .loop__status {
        > span {
          background-color: #ffffff;
        }
      }
      .loop__list--info {
        p {
          color: #ffffff;
        }
        > span {
          color: #828991;
        }
      }
      background-color: #252256;
      box-shadow: 0 2px 4px rgba(37, 34, 86, 0.34);
    }
    padding-left: 50px;
    padding-right: 45px;
    transition: 0.4s ease background-color;
  }
  .settings__loop--table {
    > .save__button {
      display: none;
      margin-right: 10px;
    }
    display: flex;
    align-items: center;
    > .settings__loop {
      display: flex;
    }
  }
}
.loop__table.draggable__loop--list {
  .elem__loop--list.ui-sortable-helper {
    .loop__list--info {
      > span {
        color: #828991;
      }
      > p {
        color: #fff;
      }
    }
    .loop__status {
      > span {
        background-color: #fff;
      }
    }
    background-color: #252256;
    box-shadow: 0 2px 4px rgba(37, 34, 86, 0.34);
  }
  .elem__loop--list {
    .remove__loop {
      display: flex;
    }
    .loop__status {
      display: flex;
    }
  }
}
.loop__table {
  .elem__loop--list {
    .remove__loop {
      display: none;
    }
    .loop__status {
      display: none;
    }
  }
}
.modal__wrapper.confirm__delete {
  max-width: 450px;
}
.confirm__delete {
  > form {
    > p {
      color: $third-color;
    }
    > h2 {
      max-width: 265px;
    }
  }
  .confirm__loop {
    background-color: #eaeef5;
  }
}
.confirm__info {
  > p {
    color: $base-color;
  }
  ul {
    li {
      color: $third-color;
    }
  }
}
.modal__wrapper.recording__modal {
  max-width: 640px;
}
.recording__controls {
  > a {
    min-height: 44px;
  }
}
.customer__controls {
  .loop__stat {
    position: relative;
    .most__viewed {
      .hot__loop {
        margin-right: 0px;
      }
      position: absolute;
      right: 0px;
      bottom: -40px;
    }
  }
}
.modal__wrapper.ownership__wrapper {
  max-width: 460px;
  max-height: calc(100% - 50px);
  overflow-y: auto;
}
.radio-group {
  input {
    &:checked {
      + {
        label {
          &:after {
            left: auto;
            right: 0px;
            top: 50%;
            transform: translate(0%, -50%);
          }
        }
      }
    }
  }
}
.ownership__wrapper {
  .ownership__element {
    &:last-child {
      border-bottom: 0px;
      padding-bottom: 0px;
      margin-bottom: 0px;
    }
    width: 100%;
    max-width: 290px;
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid $light-secondary;
  }
  max-width: 290px;
  margin-left: auto;
  margin-right: auto;
  .radio-group {
    label {
      width: 100%;
    }
  }
  .find__member {
    max-width: 290px;
  }
}
.ownership__element {
  .radio-group {
    label {
      &:before {
        top: auto;
        right: 0px;
        left: auto;
        margin-right: 0px;
        top: 50%;
        transform: translate(0%, -50%);
        position: absolute;
      }
    }
  }
}
.modal__wrapper.invite__course {
  max-width: 475px;
  overflow: hidden;
  max-height: calc(100% - 50px);
  overflow-y: auto;
}
.copy__group {
  position: relative;
  > a {
    position: absolute;
    right: 11px;
  }
}
.invite__submit {
  border-top: 1px solid $light-secondary;
  width: calc(100% + 50px);
  margin-left: 0px;
  padding-right: 25px;
}
.copy__group--locked {
  > input[type='text'] {
    background-color: #ededed;
  }
  > input {
    color: #837d99;
  }
}
.copy__double {
  > input {
    height: 38px;
    height: 42px;
  }
  > a {
    height: 42px;
  }
}
.modal__wrapper.adding__video {
  max-width: 585px;
  max-height: calc(100% - 50px);
  overflow-y: auto;
}
.materials__add {
  > input[type='file'] {
    position: absolute;
    left: -2000px;
  }
}
.modal__wrapper.save__loop {
  max-width: 415px;
  max-height: calc(100% - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}
.modal__wrapper.loop__live {
  form {
    max-width: 335px;
  }
  max-width: 460px;
  max-height: calc(100% - 50px);
  overflow-y: auto;
  overflow-x: hidden;
}
.select__member {
  .group__form {
    > p {
      color: #444444;
    }
    .select__dropdown {
      top: 70px;
      left: 0px;
      position: absolute;
      width: 100%;
      box-shadow: 0 4px 25px rgba(0, 0, 0, 0.11);
    }
  }
}
.modal__wrapper.assign__loop {
  max-width: 400px;
  max-height: calc(100% - 50px);
  overflow-y: auto;
  form {
    max-width: 300px;
  }
}
.save__controls {
  border-top: 1px solid $light-secondary;
  width: calc(100% + 45px);
  margin-left: 0px;
}
.categories__picker {
  label {
    &:before {
      margin-right: 0px;
      margin-left: 7px;
    }
    flex-direction: row-reverse;
    min-width: 120px;
    align-items: center;
    justify-content: space-between;
    color: $base-color !important;
  }
  > div {
    max-width: 120px;
    min-width: 120px;
  }
  .radio-group {
    input {
      &:checked {
        + {
          label {
            &:after {
              top: 50%;
              transform: translate(0%, -50%);
              margin-top: 2px;
            }
          }
        }
      }
    }
    label {
      span {
        img {
          min-width: 12px;
          margin-right: 7px;
        }
        display: flex;
        align-items: center;
      }
    }
  }
  span {
    color: $third-color;
  }
}
.sub__category {
  .radio-group {
    display: none;
  }
}
.channel__more {
  .radio-group {
    label {
      display: flex;
      flex-direction: row-reverse;
      justify-content: space-between;
      &:before {
        margin-right: 0px;
        margin-left: 8px;
        top: 50%;
        transform: translate(0%, -50%);
      }
      span {
        img {
          margin-right: 8px;
        }
      }
    }
    padding-bottom: 10px;
    border-bottom: 1px solid $light-secondary;
  }
  > span {
    color: $third-color;
  }
}
.channel__more--controls {
  > a {
    color: $third-color;
  }
}
.select__dropdown {
  .elem__dropdown {
    .drop__image {
      img {
        min-width: 35px;
        max-width: 35px;
        border-radius: 250px;
      }
    }
  }
}
.drop__member {
  > p {
    font-size: 13px;
    color: $base-color;
    line-height: 13px;
  }
}
.elem__dropdown.pending__dropdown {
  background-color: #e8e8e8;
  pointer-events: none;
}
.select__group.filled__group {
  .success__member {
    display: block !important;
  }
  input {
    pointer-events: none;
    background-color: rgba(0, 0, 0, 0.06);
  }
}
.float__menu {
  ul {
    li {
      a {
        &:hover {
          &:before {
            width: 100%;
          }
        }
        &:before {
          content: '';
          position: absolute;
          left: 0px;
          right: 0px;
          height: 2px;
          background-color: $base-light;
          transition: 0.4s ease all;
          width: 0px;
          bottom: -2px;
          margin-left: auto;
          margin-right: auto;
        }
        position: relative;
      }
    }
    li.active__float {
      a {
        &:before {
          width: 100%;
        }
      }
    }
  }
}
.sign__form {
  .group__info {
    > p {
      color: $third-color;
    }
  }
}

.logo__catcher {
  img {
    max-width: 115px;
  }
}
.info__drop {
  cursor: pointer;
  position: relative;
}
.wrap__v--info {
  position: relative;
  .drop__info--block {
    .drop__image {
      img {
        max-width: 100%;
      }
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 7px;
    }
    .drop__txt {
      p {
        margin: 0px;
        font-size: 14px;
        color: #000;
        font-weight: 400;
      }
    }
    position: absolute;
    top: 0px;
    min-width: 320px;
    padding: 15px;
    background-color: #fff;
    border-radius: 5px;
    z-index: 2;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    left: -40px;
    top: 24px;
    display: none;
  }
}
.arrow-up {
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #fff;
  position: absolute;
  top: 0px;
  left: 41px;
  top: -8px;
}
.info__drop.current__info {
  path {
    fill: #25b79c;
  }
}
.step__block {
  .radio-group {
    input {
      &:checked {
        + {
          label {
            &:after {
              left: 0px;
            }
          }
        }
      }
    }
  }
}
.upload__thumb {
  position: relative;
  > input[type='file'] {
    position: absolute;
    left: -2000px;
    opacity: 0;
  }
}
.step__modal {
  .radio-group {
    input {
      &:checked {
        + {
          label {
            &:after {
              left: 0px;
            }
          }
        }
      }
    }
  }
}
.add__video {
  .radio-group {
    input {
      &:checked {
        + {
          label {
            &:after {
              left: 0px;
            }
          }
        }
      }
    }
  }
}
.notification__list {
  .elem__notification {
    .notification__person {
      min-height: 35px;
      max-height: 35px;
      img {
        object-fit: cover;
        min-height: 35px;
        max-height: 35px;
        min-width: 35px;
        max-width: 35px;
        border-radius: 250px;
      }
    }
  }
}
.profile__header {
  position: relative;
  .profile__dropdown {
    ul {
      padding: 0px;
      list-style-type: none;
      margin: 0px;
      li {
        width: 100%;
        a {
          white-space: nowrap;
          display: flex;
          padding: 7px 30px;
          transition: 0.4s ease all;
          &:hover {
            background-color: #e4e9f1;
          }
        }
      }
    }
    right: 0px;
    z-index: 15;
    min-width: 200px;
    position: absolute;
    top: 44px;
    background-color: #fff;
    border-radius: 5px;
    display: none;
    padding: 10px 0px;
  }
}
.loop__table.loop__table--admin {
  .table__header {
    display: flex;
  }
}
.loop__table {
  .table__header {
    display: none;
  }
}
.created__info {
  > .top__created {
    position: relative;
  }
}
.created__profile {
  &:hover {
    opacity: 1 !important;
  }
}
.star__dropdown {
  left: 27px;
  top: 50px;
  z-index: 4;
  &:before {
    left: 6%;
  }
  .author__info {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    > p {
      font-size: 18px;
      font-weight: 500;
      color: #000;
      margin-bottom: 5px;
    }
    .points__drop {
      > p {
        span {
          margin-right: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        font-size: 14px;
        font-weight: 400;
        color: #000;
        margin: 0px;
      }
      p {
        > span {
          img {
            max-width: 12px;
            min-width: 12px;
            min-height: 12px;
            max-height: 12px;
          }
        }
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
  .auth__info--elem {
    > p {
      font-size: 15px;
    }
    > h6 {
      font-size: 12px;
      color: #8c9aa7;
    }
  }
}
.elem__watched {
  position: relative;
  .star__dropdown {
    &:before {
      left: 50%;
      transform: translate(-50%, 0);
    }
    left: 50%;
    top: 65px;
    transform: translate(-50%, 0);
  }
  .watched__image {
    > img {
      cursor: pointer;
    }
  }
}
.customer__created {
  position: relative;
  .star__dropdown {
    left: 0px;
    transform: none;
    top: 95px;
  }
}
.comment__profile {
  > img {
    cursor: pointer;
  }
  .star__dropdown {
    &:before {
      left: 8%;
    }
    left: -10px;
    transform: none;
  }
  position: relative;
}
.comment__form {
  align-items: center;
  display: flex;
  > input[type='submit'] {
    display: inline-flex;
    min-width: 120px;
    margin-left: 10px;
    max-width: 120px;
    min-height: 48px;
  }
}
.modal__block {
  .modal__wrapper.views__modal {
    max-width: 440px;
    max-height: 570px;
  }
}
.modal__wrapper.views__modal {
  > h2 {
    text-align: center;
    margin: 0px;
    margin-bottom: 20px;
  }
}
.watched__container {
  overflow-y: auto;
  max-height: 470px;
  width: calc(100% + 15px);
  padding-right: 15px;
  &::-webkit-scrollbar-track {
    background-color: #efefef;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
  }
  .elem__watched {
    .watched__info {
      p {
        font-size: 15px;
      }
    }
    .watched__image {
      margin-right: 15px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      position: relative;
      img {
        min-width: 45px;
        max-width: 45px;
        min-height: 45px;
        max-height: 45px;
        border-radius: 250px;
        object-fit: cover;
      }
    }
    padding: 10px 0px;
    border-bottom: 1px solid #cecece;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    .star__dropdown {
      &:before {
        left: 7.7%;
      }
      left: 0px;
      transform: none;
      left: 3px;
      top: 52px;
    }
  }
}
.updated__info {
  position: relative;
  padding-right: 25px;
  > a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 15px;
    top: 15px;
  }
}
.placeholder__image {
  span {
    font-size: 17px;
    color: #fff !important;
  }
  min-width: 45px;
  max-width: 45px;
  min-height: 45px;
  max-height: 45px;
  border-radius: 250px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: #504a62;
}
.profile__picker {
  > .placeholder__image {
    span {
      font-size: 32px;
    }
    min-width: 80px;
    max-width: 80px;
    min-height: 80px;
    max-height: 80px;
  }
}
.views__modal {
  h2 {
    font-family: $font-medium;
    font-weight: 500;
  }
}
.time__comment {
  font-size: 13px;
  display: inline-flex;
  margin-left: 0px;
  color: #757a81;
}
.comment__info {
  .comment__head {
    display: flex;
    flex-wrap: wrap;
    > span {
      &:nth-child(1) {
        margin-right: 5px;
        display: flex;
        align-items: center;
      }
    }
  }
}
.form__light {
  .outer__form--light {
    max-width: 555px;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.05);
  }
}
.contribution__block {
  > .contribution__top {
    background-color: #f3fcf8;
  }
}
.or__contribution {
  color: #cccccc;
  text-align: center;
  font-weight: 400;
}
.decline__bottom {
  > input[type='submit'] {
    max-width: 70px;
  }
  > input {
    max-width: 200px;
  }
}
.choose__member {
  cursor: pointer;
  padding: 5px 10px;
  display: flex;
  flex-wrap: wrap;
  overflow-y: auto;
  height: 50px;
  .elem__chosen {
    .chosen__image {
      img {
        max-width: 19px;
        min-width: 19px;
        border-radius: 250px;
      }
      display: inline-flex;
      align-items: center;
      margin-right: 7px;
      justify-content: center;
    }
    .chosen__name {
      p {
        margin: 0px;
        font-size: 10px;
      }
    }
    border-radius: 5px;
    background-color: #e9f9f1;
    padding: 2px 5px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-right: 5px;
    position: relative;
    height: 28px;
    top: 3px;
    margin-bottom: 5px;
  }
}
.select__dropdown {
  .elem__dropdown {
    padding-right: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
    .check__elem {
      position: absolute;
      right: 10px;
    }
    .form-group {
      > input {
        &:checked {
          + {
            label {
              &:after {
                border-color: #fff !important;
                top: 3px;
              }
              &:before {
                background-color: #3ccd8e !important;
              }
            }
          }
        }
      }
    }
  }
}
.select__member {
  > .group__form {
    > input {
      height: 50px;
    }
  }
}
.ownership__wrapper {
  .select__dropdown {
    max-height: 120px;
    overflow-y: auto;
  }
}
.assign__loop {
  .select__dropdown {
    max-height: 240px;
    overflow-y: auto;
  }
}
.always__hidden.elem__chosen {
  display: none !important;
}
.drop__member {
  > span {
    word-break: break-all;
  }
}
.find__member.select__member {
  position: relative;
}
.select__channel--dropdown {
  width: 100%;
  position: relative;
  > input {
    width: 100%;
  }
  .select__channel--droppable {
    ul {
      padding: 0px;
      list-style-type: none;
      margin: 0px;
      li {
        width: 100%;
        a {
          &:hover {
            background-color: #e4e9f1;
          }
          white-space: nowrap;
          display: flex;
          padding: 7px 15px;
          transition: 0.4s ease all;
        }
      }
    }
    position: absolute;
    left: 0px;
    top: 45px;
    padding: 0px 0px;
    width: 100%;
    display: none;
    background-color: #fff;
    z-index: 3;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.05);
  }
}
.select__channel--input {
  background: url('../img/arrowselect.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 96% center;
}
.select__subchannel--input {
  background: url('../img/arrowselect.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 96% center;
}
.add__subchannel--block {
  position: relative;
  .select__subchannel--droppable {
    ul {
      padding: 0px;
      list-style-type: none;
      margin: 0px;
      li {
        width: 100%;
        a {
          &:hover {
            background-color: #e4e9f1;
          }
          white-space: nowrap;
          display: flex;
          padding: 7px 15px;
          transition: 0.4s ease all;
        }
      }
    }
    position: absolute;
    left: 0px;
    top: 65px;
    padding: 0px 0px;
    width: 100%;
    display: none;
    background-color: #fff;
    z-index: 3;
    max-height: 150px;
    overflow-y: auto;
    box-shadow: 0 7px 10px rgba(0, 0, 0, 0.05);
  }
}
.create__new--channel {
  .head__new--channel {
    > p {
      color: $third-color;
    }
  }
}
.content__new--channel {
  > a {
    max-width: 80px;
    margin-left: 15px;
  }
}
.content__new--subchannel {
  > a {
    max-width: 80px;
    margin-left: 15px;
  }
}
.member__block {
  .member__profile {
    position: relative;
    .star__dropdown {
      left: 0px;
      transform: none;
      top: 35px;
      &:before {
        left: 1%;
      }
    }
  }
}
.list__member {
  .member__block {
    > a {
      color: $dark-grey;
    }
  }
}
.member__list {
  .list__member {
    .member__block {
      margin-bottom: 7px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
.float__menu {
  ul {
    li.active__float {
      a {
        &:before {
          background-color: $base-light;
          width: 85%;
        }
      }
    }
  }
}
.knowledge__info {
  .checkout__button {
    > a {
      max-width: 325px;
    }
  }
}
.form__wrapper.form__knowledge {
  max-width: 590px;
  padding-left: 25px;
  padding-right: 25px;
}
.billing__details {
  max-width: 1140px;
  padding-left: 0px;
  padding-right: 0px;
  margin-top: 50px;
  .billing__plan--wrapper {
    > .billing__plan {
      .content__billing {
        padding: 15px 25px;
        .elem__billing.month__billin {
          padding-bottom: 12px;
          margin-bottom: 12px;
          border-bottom: 1px dashed $light-secondary;
        }
        .elem__billing {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 12px;
          > p {
            margin: 0px;
            color: $third-color;
            font-size: 16px;
            font-weight: 400;
          }
          > span {
            white-space: nowrap;
            font-size: 16px;
            display: inline-flex;
            margin-left: 10px;
            font-weight: 400;
            color: $third-color;
          }
        }
        .total__billing {
          > span {
            color: $base-color;
            font-size: 16px;
            font-weight: 400;
          }
          > p {
            margin: 0px;
            color: $base-color;
            font-family: 'CircularMedium', sans-serif;
            font-weight: 500;
          }
          display: flex;
          align-items: center;
          justify-content: space-between;
          border-top: 1px solid #d8d7dd;
          padding-top: 12px;
          margin-bottom: 15px;
        }
      }
      .head__billing {
        > p {
          margin: 0px;
          font-size: 15px;
          font-weight: 400;
          color: #fff;
        }
        > h6 {
          margin: 0px;
          font-size: 18px;
          color: #fff;
          font-family: 'CircularBold', sans-serif;
          font-weight: bold;
        }
        padding: 15px 25px;
        background-color: $base-light;
        border-top-left-radius: 6px;
        border-top-right-radius: 6px;
      }
      border-radius: 6px;
      background-color: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.16);
      max-width: 300px;
    }
    .after__billing {
      > p {
        color: #9c9c9e;
      }
    }
  }
  .billing__form {
    .head__billing--details {
      > h6 {
        margin: 0px;
        margin-bottom: 10px;
      }
    }
    background-color: #ffffff;
    border-radius: 6px;
    padding: 25px 35px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.18);
  }
}
.billing__plan--wrapper {
  max-width: 300px;
  min-width: 300px;
  margin-left: 25px;
}
.billing__after--plan {
  > p {
    color: $third-color;
  }
}
.column__billing--form {
  > p {
    border-bottom: 1px solid #e2e2e2;
    padding-bottom: 10px;
  }
}
.pricing__base {
  .elem__pricing {
    .pricing__button {
      position: absolute;
      bottom: 20px;
      left: 1.75rem;
      width: calc(100% - 3.5rem);
    }
    padding-bottom: 100px;
    position: relative;
  }
}
.limited__free {
  .free__left {
    > p {
      margin: 0px;
    }
    > h6 {
      color: $third-color;
    }
  }
  border: 1px solid $light-secondary;
  .free__right {
    > a {
      min-width: 240px;
    }
  }
}
.select__dropdown.choose__member--dropdown {
  max-height: 150px;
  overflow-y: auto;
  &::-webkit-scrollbar-track {
    background-color: #efefef;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #a6a6a6;
  }
}
.modal__wrapper.sub__modal {
  .radio-group {
    input {
      &:checked {
        + {
          label {
            &:after {
              left: 0px !important;
            }
          }
        }
      }
    }
  }
}
.infinity-loader {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 130px;
  height: 70px;
  .bg {
    div {
      width: 70px;
      height: 70px;
      border: 10px solid #aaa;
      box-sizing: border-box;
      border-radius: 50%;
      position: absolute;
    }
  }
  > .fg {
    > div {
      > div {
        width: 70px;
        height: 70px;
        border: 10px solid #aaa;
        box-sizing: border-box;
        border-radius: 50%;
        position: absolute;
        border-color: orangered orangered transparent transparent;
        transform: rotate(135deg);
        animation: spin 1s linear infinite;
        position: static;
      }
      clip: rect(0, 70px, 35px, 0);
      position: absolute;
    }
    > .bottom-right-rect {
      left: -10px;
      transform: translateX(100%) scale(1, -1);
      > div {
        animation-delay: 0.25s;
      }
    }
    > .top-right-rect {
      left: -10px;
      transform: translateX(100%) scale(-1, 1);
      > div {
        animation-delay: 0.5s;
      }
    }
    > .bottom-left-rect {
      transform: scale(-1);
      > div {
        animation-delay: 0.75s;
      }
    }
    filter: drop-shadow(0 0 6px orangered);
  }
  .right-bg {
    transform: translate(100%, 0);
    left: -10px;
  }
}
.elem__channel {
  width: calc(100% + 40px);
  margin-left: -20px;
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.opened__channel--video {
  margin-bottom: 45px;
}
.table__loop--list {
  > .elem__loop--list {
    cursor: pointer;
    transition: 0.4s ease all;
    &:hover {
      background: #cad2dd;
    }
  }
}
.customer__controls {
  > .loop__stat {
    flex-direction: row-reverse;
    .most__viewed {
      position: static;
      margin-right: 15px;
    }
  }
}
.toggle__video {
  h6 {
    a {
      &:hover {
        opacity: 0.7;
      }
      text-decoration: none;
      font-size: 14px;
      font-weight: 400;
      color: $base-light;
      display: inline-flex;
      margin-left: 5px;
      transition: 0.4s ease all;
    }
  }
  .video__list--wrap {
    grid-template-columns: repeat(5, 1fr);
  }
}
.video__container {
  display: block !important;
  position: relative;
  .video__side {
    position: absolute;
    left: 0px;
    top: 0px;
    height: 100%;
  }
}
.video__main {
  padding-left: 312px;
  padding-right: 35px;
}
.search__inner--resultbox {
  .elem__search--result {
    .search__result--image {
      > img {
        max-width: 175px;
        border-radius: 2px;
      }
      span {
        position: absolute;
        right: 3px;
        bottom: 3px;
        font-size: 11px;
        color: #fff;
        padding: 1px 2px;
        background-color: rgba(0, 0, 0, 0.71);
        border-radius: 3px;
      }
    }
    margin-bottom: 20px;
    border-bottom: 1px solid #e8ecf3;
    padding-bottom: 20px;
    transition: 0.4s ease all;
    padding: 15px;
    margin-bottom: 0px;
    &:hover {
      background-color: #f7fafc;
    }
  }
}
.video__list--container {
  .opened__container.video__list {
    display: block;
  }
}
.search__author {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 10px;
  margin-bottom: 10px;
  .search__author--image {
    img {
      max-width: 21px;
      min-width: 21px;
      max-height: 21px;
      min-height: 21px;
      border-radius: 250px;
    }
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
    position: relative;
  }
  .search__rates {
    p {
      span {
        display: inline-flex;
        margin-right: 5px;
      }
      margin: 0px;
      display: flex;
      color: $third-color;
      font-weight: 400;
      font-size: 11px;
      align-items: center;
      justify-content: flex-start;
    }
  }
}
.progress__search {
  > p {
    margin: 0px;
    margin-bottom: 5px;
    color: #938fa2;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 400;
  }
  min-width: 170px;
  max-width: 170px;
  > span {
    display: inline-flex;
    margin-top: 5px;
    color: $third-color;
    font-size: 14px;
    font-weight: 400;
  }
  .progress__inner {
    .active__progress {
      background-color: $base-light;
      border-radius: 3px;
      height: 100%;
    }
    width: 100%;
    background-color: #dff8ed;
    border: 1px solid #baefd7;
    border-radius: 3px;
    height: 5px;
  }
}
.search__column {
  .total__time {
    .time__inner {
      p {
        img {
          margin-right: 10px;
          max-width: 18px;
        }
        display: flex;
        align-items: center;
        justify-content: flex-start;
        color: $third-color;
        font-size: 14px;
        font-weight: 400;
        margin: 0px;
      }
    }
    > p {
      margin: 0px;
      margin-bottom: 10px;
      color: #938fa2;
      font-size: 11px;
      font-weight: 400;
      text-transform: uppercase;
    }
  }
  margin-right: 20px;
  &:last-child {
    margin-right: 0px;
  }
}
.loop__data--search {
  position: relative;
  min-width: 160px;
  .data__search {
    ul {
      padding: 0px;
      list-style-type: none;
      margin: 0px;
      li {
        a {
          > span.state__data {
            display: inline-flex;
            min-width: 18px;
            margin-left: 6px;
            justify-content: center;
            align-items: center;
          }
          white-space: nowrap;
          padding-top: 5px;
          padding-bottom: 5px;
          display: flex;
          align-items: center;
          padding-left: 15px;
          font-size: 15px;
          color: $third-color;
          padding-right: 50px;
          transition: 0.4s ease all;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
    position: relative;
    position: absolute;
    right: 0px;
    top: 30px;
    padding: 15px 0px;
    background-color: #fff;
    border-radius: 4px;
    min-width: 205px;
    box-shadow: 0 6px 27px rgba(0, 0, 0, 0.13);
    z-index: 3;
    &:before {
      content: '';
      position: absolute;
      left: 120px;
      top: -6px;
      width: 12px;
      height: 6px;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #fff;
    }
  }
}
.data__search {
  .current__data {
    background: url('../img/arrowfilled.svg');
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 90% center;
  }
}
.srch__video--list {
  .srch__avatar {
    > span {
      display: inline-flex;
      min-width: 43px;
      min-height: 17px;
      align-items: center;
      justify-content: center;
      border-radius: 8.5px;
      background-color: #ffffff;
      box-shadow: 0 1px 3px rgba(0, 0, 0, 0.13);
      color: $third-color;
      font-size: 11px;
      font-weight: 400;
      padding: 1px 4px;
      img {
        max-width: 10px;
        min-width: 10px;
        margin: 0px;
        margin-right: 3px;
        max-height: 10px;
        min-height: 10px;
      }
    }
    > img {
      max-width: 35px;
      min-width: 35px;
      max-width: 35px;
      min-height: 35px;
      border-radius: 250px;
      margin-bottom: -9px;
    }
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-right: 13px;
    min-width: 45px;
    max-width: 50px;
  }
  padding-right: 30px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.list__desc {
  > ul {
    > li {
      &:last-child {
        white-space: nowrap;
      }
    }
  }
}
.list__image {
  .list__progress {
    .active__list--progress {
      height: 100%;
      background-color: $base-light;
      border-radius: 9px;
    }
    position: absolute;
    left: 10px;
    top: 10px;
    width: calc(100% - 20px);
    height: 4px;
    border-radius: 9px;
    background-color: rgba(0, 0, 0, 0.4);
  }
}
.video__list--description {
  &:last-child {
    padding-right: 0px;
  }
}
.search__result--box {
  padding-top: 50px;
  padding-bottom: 25px;
}
.outer__result--search {
  .wrapper__search--result {
    margin-bottom: 40px;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    > .search__result--info {
      width: 100%;
      margin-top: -15px;
      > h6 {
        span {
          color: $base-color;
          font-family: $font-medium;
          font-weight: 500;
          font-size: 22px;
        }
        color: $base-color;
        font-family: $font-medium;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
      }
    }
    &:last-child {
      margin-bottom: 0px;
    }
    .search__result--title {
      min-width: 200px;
    }
  }
  .wrapper__search--result.search__result--heading {
    > .search__result--info {
      margin-top: 0px;
    }
  }
}
.elem__search--result {
  .multiple__overlay {
    > span {
      position: static;
    }
    p {
      font-size: 13px;
    }
    max-width: 80px;
    min-width: 80px;
  }
  .list__image {
    span {
      position: absolute;
      right: 7px;
      bottom: 7px;
      background-color: rgba(0, 0, 0, 0.71);
    }
    .multiple__overlay {
      > span {
        position: static;
      }
    }
    max-width: 175px;
    min-width: 175px;
    margin-right: 1.25rem;
    margin-bottom: 0px;
    > img {
      max-width: 175px;
      min-width: 175px;
    }
  }
}
.total__time {
  min-width: 190px;
  max-width: 190px;
}
.srch__avatar {
  position: relative;
  .star__dropdown {
    left: 0px;
    transform: none;
  }
}
.video__list--wrap.slick-initialized.slick-slider {
  .elem__video--list {
    padding-bottom: 320px;
    margin-bottom: -320px;
  }
}
.search__rates {
  padding: 2px 8px;
  background-color: #fff;
  border-radius: 250px;
}
.search__result--info {
  > h6 {
    display: -webkit-box;
    line-height: 19px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.srch__info {
  > h6 {
    display: -webkit-box;
    line-height: 19px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.wrapper__search--result.search__result--heading {
  > .search__result--title {
    > p {
      font-size: 18px;
    }
  }
}
.search__result--title {
  > p {
    font-weight: 500;
    font-family: $font-medium;
    color: #000;
  }
}
.search__inner--box {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  .search__result--title {
    min-width: 130px;
    margin-right: 25px;
    max-width: 130px;
    min-width: 205px;
    max-width: 205px;
    p {
      br {
        display: none;
      }
    }
  }
  .search__inner--resultbox {
    width: 100%;
  }
}
.more__results {
  margin-left: 155px;
}

.infinity__back {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
  & .infinity__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 120px;
  }
}
.elem__video--list {
  position: relative;
}
.load__video--placeholder {
  .top__video--placeholder {
    height: 145px;
    margin-bottom: 10px;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0)
    );
    background-color: #e2e5e7;
    background-size: 40px 100%;
    background-repeat: no-repeat;
    background-position: left -40px top 0;
    animation: shine 1s ease infinite;
  }
}

.bottom__video--placeholder {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}
.left__bottom--place {
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  margin-right: 10px;
  border-radius: 250px;
  background-color: #e2e5e7;
}
.right__bottom--place {
  width: 100%;
}
.elem__string--place {
  height: 14px;
  margin-bottom: 10px;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0)
  );
  background-color: #e2e5e7;
  background-size: 40px 100%;
  background-repeat: no-repeat;
  background-position: left -40px top 0;
  animation: shine 1s ease infinite;
  width: 100%;
}
.elem__string--place:last-child {
  margin-bottom: 0px;
}
.load__video--placeholder {
  position: absolute;
  z-index: 2;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: #edf1f4;
}

.elem__channel {
  padding: 5px 10px;
  padding-right: 40px;
}
.elem__channel.opened__main--channel {
  background-color: $base-light;
  > p {
    color: #fff !important;
  }
  path {
    fill: #fff;
  }
}
.channel__list {
  .elem__channel {
    > span {
      right: 10px;
    }
  }
  .elem__channel.opened__main--channel {
    > span {
      background-color: #fff;
      color: $base-light;
    }
  }
}
.loop__video {
  .edit__button {
    right: 15px;
    display: flex;
    align-items: center;
  }
}
.expand__button {
  &:hover {
    path {
      fill: #fff;
    }
  }
}
.edit__button {
  > .expand__button {
    margin-left: 10px;
    min-height: 36px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}
.loop__info--box.double__box {
  .loop__table {
    margin-left: 20px;
    margin-top: 20px;
  }
  display: flex;
}
.minimize__button {
  display: none;
}
.select__member {
  .group__form {
    .select__dropdown {
      z-index: 3;
    }
  }
}
.video__menu {
  > a {
    > span {
      position: relative;
      top: 0px;
      min-width: 27px;
      min-height: 3px;
      border-radius: 5px;
      background-color: $base-color;
      margin-bottom: 5px;
      transition: 0.4s ease all;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
}
.locked__list--image {
  background: url('../img/lockicon.png');
  background-position: center;
  background-size: auto 70%;
  background-repeat: no-repeat;
}
.multiple__overlay {
  > span {
    background-color: transparent !important;
  }
}
.inner__search--box {
  position: relative;
  .infinity__back {
    .infinity-loader {
      position: absolute;
    }
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    z-index: 9;
  }
}
.elem__search--result {
  .multiple__overlay {
    > span {
      position: static !important;
    }
  }
}
.overlay {
  display: none;
}
.subchannel__block {
  width: calc(100% + 40px);
  margin-left: -20px;
  background-color: #ebecef;
  padding-left: 40px;
  .current__sub {
    a {
      color: #fff;
      > span {
        > img {
          &:nth-child(1) {
            display: none;
          }
          &:nth-child(2) {
            display: block;
          }
        }
      }
    }
    background-color: $base-light;
  }
  > a {
    color: $third-color;
  }
  ul {
    li {
      a {
        color: $third-color;
      }
    }
  }
}
.locked__channel {
  background: url('../img/lockchannel.svg');
  background-repeat: no-repeat;
  background-size: auto;
  background-position: 90% center;
  padding-right: 50px;
}
.modal__wrapper.lock__access {
  max-width: 525px;
}
.request__back {
  > a {
    min-width: 170px;
    min-height: 45px;
  }
}
.opened__container {
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.modal__wrapper.add__video {
  max-width: 455px;
  max-height: calc(100% - 50px);
  overflow-y: auto;
}
.video__inner--frame.dark__overlay {
  &:before {
    opacity: 1;
  }
}
.video__inner--frame {
  &:before {
    content: '';
    position: absolute;
    left: 0px;
    top: 0px;
    opacity: 0;
    width: 100%;
    transition: 0.4s ease all;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
  }
  > a {
    position: absolute;
    z-index: 3;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
.submit__video {
  width: calc(100% + 45px);
  margin-left: -22.5px;
  padding-right: 22.5px;
  border-top: 1px solid $light-secondary;
}
.float__menu {
  ul {
    li {
      a {
        &:hover {
          &:before {
            background-color: $base-light;
            width: 80%;
          }
          opacity: 1;
        }
        font-weight: 500;
        font-family: 'CircularMedium', sans-serif;
        &:before {
          width: 80%;
          background-color: #e1e1e1;
          bottom: -5px;
          right: auto;
          left: 0px;
        }
      }
    }
  }
}
.toggle__video {
  .video__list--wrap {
    display: grid !important;
    grid-column-gap: 15px !important;
    grid-row-gap: 15px !important;
  }
  .slick-prev {
    &:before {
      content: none;
    }
    min-width: 40px;
    max-width: 40px;
    border-radius: 250px;
    min-height: 40px;
    max-height: 40px;
    top: 75px;
    background: url('../img/prevarrow.svg') !important;
    background-repeat: no-repeat !important;
    background-size: auto !important;
    background-position: center !important;
    background-color: #fff !important;
    z-index: 3;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }
  .slick-next {
    &:before {
      content: none;
    }
    min-width: 40px;
    max-width: 40px;
    border-radius: 250px;
    min-height: 40px;
    top: 75px;
    max-height: 40px;
    background: url('../img/nextarrow.svg') !important;
    background-repeat: no-repeat !important;
    background-size: auto !important;
    background-position: center !important;
    background-color: #fff !important;
    z-index: 3;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  }
}
.toggle__video.video__slider {
  .video__list--wrap {
    display: block !important;
    grid-column-gap: 0px !important;
    grid-row-gap: 0px !important;
  }
  .slick-slide {
    margin: 0 12.5px;
  }
  .slick-list {
    margin: 0 -12.5px;
  }
}
.search__results--inner {
  .search__inner--header {
    p {
      span {
        font-weight: bold;
        font-family: $font-bold;
      }
    }
  }
}
.search__result--list {
  > h6 {
    color: $base-color;
  }
}
.search__results--main {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 15px;
  grid-row-gap: 30px;
}
.video__list--description {
  padding-right: 30px;
}
.search__inner--resultbox {
  .elem__search--result {
    overflow: hidden;
  }
}
.member__small--title {
  display: none;
}
.search__mobile {
  display: none;
}
.draggable__loop--list {
  .elem__loop--list {
    &:hover {
      box-shadow: none !important;
      .loop__list--info {
        p {
          color: $base-color;
        }
      }
      .loop__status {
        > span {
          background-color: #91a7cc;
        }
      }
    }
  }
}
.table__loop--list.ui-sortable {
  .elem__loop--list {
    transition: none;
  }
}

.float__dropdowns {
  & > .star__dropdown {
    position: absolute;
    transform: translate(0%, 0);
    margin-top: 50px;
  }
}
@media (max-width: 1440px) {
  .toggle__video {
    .video__list--wrap {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}

@media (max-width: 1280px) {
  .watched__inner {
    .elem__watched {
      padding: 0px;
    }
    .elem__watched--more {
      padding: 0px;
    }
  }
  .who__watched {
    .watched__inner {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px;
    }
  }
  .top__plan {
    .plan__info--elem {
      min-width: 150px;
    }
  }
  .video__list--container {
    margin-top: 10px;
  }
  .notification__drop {
    right: 0px;
    &:before {
      right: 14px;
    }
  }
  .platform__block {
    .platform__info {
      max-width: 400px;
    }
  }

  .global__block {
    border-bottom: 1px solid #e5e5e5;
    & .outer__global {
      & .global__features {
        & .elem__global--feature {
          &:before {
            right: -16px;
          }
        }
      }
    }
  }
  .settings__content {
    & .settings__profile--picture {
      margin-left: 0px;
    }
  }
}
@media (max-width: 1200px) {
  .search__results--main {
    grid-template-columns: repeat(3, 1fr);
  }
  .search__inner--box {
    .search__result--title {
      margin-right: 15px;
    }
  }
  .toggle__video {
    .video__list--wrap {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  .search__results--main {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 1024px) {
  .more__results {
    margin-left: 0px;
    display: flex;
    justify-content: center;
  }
  .search__inner--box {
    margin-bottom: 15px;
    flex-direction: column;
    &:last-child {
      margin-bottom: 0px;
    }
  }
  .loop__data--search {
    min-width: 134px;
  }
  .search__inner--resultbox {
    .elem__search--result {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .search__column {
    width: 100%;
    margin-bottom: 20px;
    margin-right: 0px;
    &:last-child {
      margin-bottom: 0px;
    }
    .progress__search {
      max-width: 100%;
      min-width: 0px;
      width: 100%;
    }
  }
  .video__menu {
    margin-bottom: 10px;
    margin-top: -10px;
  }
  .search__results--main {
    grid-template-columns: repeat(3, 1fr);
  }
  .video__main {
    padding-left: 15px;
    padding-right: 15px;
  }
  .toggle__video {
    .slick-prev {
      left: -10px;
    }
    .slick-next {
      right: -10px;
    }
  }
  .watched__container {
    .elem__watched {
      .star__dropdown {
        &:before {
          left: 10%;
        }
      }
    }
    max-height: 390px;
  }
  .star__dropdown {
    min-width: 200px;
    max-width: 200px;
  }
  .loop__main {
    .loop__table {
      position: fixed;
      top: 0px;
      right: -280px;
      width: 280px;
      min-width: 0px;
      height: calc(100%);
      overflow-y: auto;
      z-index: 15;
      transition: 0.7s ease all;
    }
  }
  .bookmarked__list {
    &:nth-child(1) {
      margin-top: 0px;
    }
  }
  .request__back {
    > a {
      min-width: 140px;
    }
  }
  .channel__settings {
    .channel__dropdown {
      left: auto;
      right: 0px;
    }
  }
  .overlay {
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    display: none;
    z-index: 15;
    background-color: rgba(0, 0, 0, 0.7);
  }
  .video__container {
    .video__side {
      display: block;
      position: fixed;
      z-index: 20;
      top: 0px;
      min-height: 100%;
      max-height: 100%;
      overflow-y: auto;
      min-width: 240px;
      max-width: 240px;
      left: -240px;
      transition: 0.7s ease all;
    }
  }
  .member__controls {
    .controls__dropdown {
      right: 10px;
    }
  }
  .table__members {
    overflow-x: scroll;
    .content__members {
      width: 790px;
    }
  }
  .top__plan {
    .plan__info--elem {
      min-width: 180px;
    }
  }
  .inner__pricing--switcher {
    > .elem__pricing {
      > .head__pricing {
        > p {
          min-height: 0px;
        }
      }
    }
  }
  .elem__small--feedback {
    .feedback__author {
      .author__details {
        max-width: 180px;
      }
    }
  }
  .platform__block {
    .platform__info {
      max-width: 100%;
    }
  }
  .float__menu {
    ul {
      li {
        a {
          color: #fff;
          &:before {
            width: 100%;
            content: none;
          }
        }
      }
    }
    top: -100%;
    transition: 0.8s ease all;
    background-color: $base-light;
  }
  .menu__button {
    > a {
      > span {
        &:last-child {
          margin-bottom: 0px;
          top: auto;
          bottom: 0px;
        }
        position: relative;
        top: 0px;
        bottom: auto;
        min-width: 24px;
        min-height: 2px;
        background-color: $base-color;
        transition: 0.4s ease all;
        margin-bottom: 5px;
      }
    }
    > a.active__menu {
      > span {
        background-color: #fff;
        &:nth-child(1) {
          transform: rotate(45deg);
          top: 6px;
        }
        &:nth-child(3) {
          transform: rotate(-45deg);
          bottom: 7px;
        }
        &:nth-child(2) {
          opacity: 0;
        }
      }
    }
  }
  .modal__block {
    .modal__wrapper.views__modal {
      max-height: 490px;
    }
  }
  .billing__details {
    flex-direction: column-reverse;
    .billing__plan--wrapper {
      > .billing__plan {
        max-width: 100%;
      }
    }
  }
  .billing__plan--wrapper {
    min-width: 0px;
    max-width: 100%;
    width: 100%;
    margin-bottom: 25px;
    margin-left: 0px;
  }
  .loop__info--box.double__box {
    .loop__table {
      margin-top: 0px;
    }
  }
  .expand__button {
    display: none !important;
  }

  header {
    & .search__header {
      width: 100%;
      max-width: 280px;
    }
    & .search__box {
      & .inner__search--box {
        height: calc(100% - 80px);
      }
    }
  }
  .global__block {
    border-bottom: 1px solid #e5e5e5;
    & .outer__global {
      & .global__features {
        & .elem__global--feature {
          &:before {
            top: auto;
            left: 0px;
            right: 0px;
            bottom: -32px;
            width: 100%;
            height: 1px;
          }
        }
      }
    }
  }
  .join__block {
    & .outer__join {
      & .features__list {
        & .elem__feature {
          &:nth-child(3n) {
            border-right: 1px solid rgba(0, 0, 0, 0.07);
          }
          &:nth-child(4n) {
            border-right: 0px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.07);
          }
          &:nth-child(6n) {
            border-right: 0px;
          }
          &:nth-child(even) {
            border-right: 0px;
          }
        }
      }
    }
  }
}
@media (min-width: 991px) {
  .fullscreen.infinity-loader {
    transform: scale(2.5);
    transform: scale(2.5) translate(-50%, -50%);
    margin-left: auto;
    margin-right: auto;
    margin-left: 87px;
  }
  .fullscreen.infinity__inner {
    > p {
      font-size: 24px;
    }
  }
}

@keyframes spin {
  50%,
  100% {
    transform: rotate(495deg);
  }
}

@keyframes shine {
  to {
    background-position: right -40px top 0;
  }
}

@media (max-width: 767px) {
  .more__results {
    margin-top: 0px;
  }
  .search__inner--resultbox {
    .elem__search--result {
      padding: 10px;
    }
  }
  .outer__result--search {
    .wrapper__search--result {
      > .search__result--info {
        margin-top: 0px;
      }
      .search__result--title {
        margin-bottom: 10px;
        min-width: 0px;
      }
      flex-direction: column;
      width: 100%;
      margin-bottom: 25px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .wrapper__search--result.search__result--heading {
      > .search__result--title {
        margin-bottom: 0px;
        margin-top: 10px;
      }
      flex-direction: column-reverse;
    }
  }
  .search__result--info {
    > h6 {
      line-height: 16px;
    }
  }
  .srch__info {
    > h6 {
      line-height: 16px;
    }
  }
  .search__column {
    &:nth-child(1) {
      margin-bottom: 10px;
    }
  }
  .loop__data--search {
    .data__search {
      ul {
        li {
          a {
            font-size: 14px;
          }
        }
      }
    }
  }
  header {
    .search__box {
      .inner__search--box {
        height: 100%;
      }
    }
  }
  .search__results--main {
    grid-template-columns: repeat(2, 1fr);
  }
  .search__mobile {
    display: none;
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    background-color: #e8ecf3;
    z-index: 2;
  }
  .inner__search--mobile {
    > input {
      width: 100%;
      height: 34px;
      background-color: #f6f8fb;
    }
    > a {
      margin-left: 10px;
    }
    padding-left: 15px;
    padding-right: 15px;
    max-width: 640px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 63px;
  }
  .header__right {
    .small__search {
      display: flex;
      min-width: 30px;
      margin-right: 10px;
      > a {
        display: flex;
        padding: 5px;
        background-color: #d3ddef;
        min-height: 38px;
        min-width: 38px;
        align-items: center;
        justify-content: center;
        img {
          width: 16px;
        }
      }
    }
  }
  .toggle__video {
    .video__list--wrap {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  .member__small--title {
    display: block;
    min-width: 110px;
    max-width: 110px;
    > p {
      font-size: 14px;
      color: #000;
    }
  }
  .content__members {
    > .title__members {
      display: none;
    }
  }
  .elem__member {
    display: grid;
    grid-template-columns: repeat(2, auto);
    align-items: center;
    grid-row-gap: 15px;
    justify-content: flex-start;
    > div.member__profile {
      min-width: 0px;
    }
  }
  .title__members {
    > p {
      &:nth-child(1) {
        min-width: 0px;
      }
    }
  }
  .member__controls {
    .controls__dropdown {
      left: 50%;
      transform: translate(-50%, 0);
    }
  }
  .table__members {
    .content__members {
      width: auto;
    }
    width: calc(100% + 15px);
  }
  .pricing__base {
    .elem__pricing {
      .pricing__button {
        width: calc(100% - 2.5rem);
        left: 1.25rem;
      }
    }
  }
  .limited__free {
    .free__right {
      width: calc(100% - 0.5rem);
      > a {
        min-width: 0px;
        width: 100%;
      }
    }
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .free__left {
      text-align: center;
      margin-bottom: 15px;
    }
  }
  .contribution__block {
    > .contribution__top {
      flex-direction: column;
    }
  }
  .decline__bottom {
    flex-direction: row;
  }
  .loop__info {
    > .customer__created {
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0px;
      }
    }
  }
  .customer__controls {
    .loop__stat {
      .most__viewed {
        position: static;
        margin-top: 10px;
      }
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
  .submit__video {
    width: calc(100% + 20px);
    margin-left: -10px;
    padding-right: 10px;
    padding-left: 10px;
  }
  .video__list--wrap.triple__video--list {
    grid-template-columns: repeat(2, minmax(140px, 260px));
  }
  .modal__block {
    .invite__button {
      width: calc(100% + 20px);
      margin-left: -10px;
      padding-right: 10px;
    }
  }
  .relative__group {
    top: 0px;
  }
  select {
    background-position: 98% center;
  }
  .camera--main {
    > span {
      top: 12px;
      left: 12px;
    }
  }
  .top__plan {
    .plan__info--elem {
      min-width: 0px;
    }
  }
  .outer__settings {
    ul {
      li {
        a {
          &:before {
            bottom: 0px;
          }
        }
      }
    }
  }
  .points__header {
    > a {
      > p {
        position: relative;
        top: -1px;
      }
    }
  }
  .scale__block {
    .scale__details {
      max-width: 100%;
    }
  }
  .settings__content {
    & .settings__profile--picture {
      max-width: none;
    }
    & .settings__profile {
      width: 100%;
      margin-left: 0px;
    }
  }
  .join__block {
    & .outer__join {
      & .join__features {
        & .feature__info {
          max-width: 100%;
        }
      }
      & .features__list {
        & .elem__feature {
          border-right: 0px !important;
          border-bottom: 1px solid rgba(0, 0, 0, 0.07);
          &:nth-child(5) {
            border-bottom: 1px solid rgba(0, 0, 0, 0.07);
          }
          &:last-child {
            border-bottom: 0px !important;
          }
        }
      }
    }
  }
  .settings__head {
    & .outer__settings {
      & ul {
        & li {
          & a {
            &:before {
              bottom: 0px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 640px) {
  .watched__container {
    .elem__watched {
      .star__dropdown {
        &:before {
          left: 11%;
        }
      }
    }
    max-height: 320px;
  }
  .modal__block {
    .modal__wrapper.views__modal {
      max-height: 420px;
    }
  }
  .star__dropdown {
    min-width: 170px;
    max-width: 170px;
    padding: 10px;
    .auth__info--elem {
      > p {
        font-size: 14px;
      }
    }
    .author__info {
      > p {
        font-size: 16px;
      }
    }
  }
}
@media (max-width: 540px) {
  .who__watched {
    .watched__inner {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 15px;
      grid-row-gap: 15px;
    }
  }
}
@media (max-width: 480px) {
  .search__inner--box {
    margin-bottom: 0px;
  }
  .search__inner--resultbox {
    .elem__search--result {
      .search__result--image {
        > img {
          width: 100%;
          max-width: 100%;
        }
      }
      padding-bottom: 15px;
      margin-bottom: 15px;
    }
  }
  .search__result--image {
    max-width: 100%;
    width: 100%;
    margin-right: 0px !important;
    margin-bottom: 10px;
  }
  .search__result--box {
    padding-top: 30px;
    padding-bottom: 15px;
  }
  .search__column {
    &:nth-child(1) {
      margin-bottom: 20px;
    }
    flex-direction: column;
  }
  .elem__search--result {
    .list__image {
      img {
        max-width: 100%;
      }
      max-width: 100%;
      margin-right: 0px;
      margin-bottom: 15px;
    }
  }
  .search__feedback--info {
    display: flex;
    justify-content: flex-start;
  }
  .search__result--info {
    width: 100%;
  }
  .video__menu {
    margin-bottom: 15px;
  }
  .search__results--main {
    grid-template-columns: 1fr;
  }
  header {
    .search__box {
      .inner__search--box {
        padding: 15px 0px;
      }
    }
  }
  .member__small--title {
    > p {
      font-size: 13px;
    }
    min-width: 100px;
    max-width: 100px;
  }
  .billing__details {
    .billing__form {
      padding: 20px 15px;
    }
    margin-top: 30px;
  }
  .comment__form {
    flex-direction: column;
    justify-content: center;
    textarea {
      width: 100%;
    }
    > input[type='submit'] {
      margin-left: auto;
      margin-right: 0px;
      margin-top: 10px;
    }
  }
  .comment__field {
    .profile__comment {
      margin-top: 0px;
      margin-bottom: auto;
    }
  }
  .pending__status {
    span {
      white-space: inherit;
      line-height: 15px;
      text-align: center;
      display: flex;
    }
  }
  .elem__dropdown.pending__dropdown {
    padding-left: 12px;
    padding-right: 12px;
  }
  .drop__member {
    > p {
      font-size: 12px;
    }
    > span {
      font-size: 11px;
    }
  }
  .copy__double {
    flex-direction: column;
    justify-content: center;
    flex-direction: column-reverse;
    > input {
      margin-bottom: 10px;
    }
    > a {
      margin-right: 0px;
    }
  }
  .modal__wrapper {
    .close__modal {
      top: 8px;
      right: 8px;
    }
    .invite__member {
      .member__submit {
        margin-left: 0px;
        padding-top: 10px;
      }
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .recording__modal {
    > form {
      > h2 {
        br {
          display: none;
        }
      }
    }
  }
  .loop__stat {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    .hot__loop {
      margin-bottom: 0px;
      margin-bottom: 12px;
    }
  }
  .video__main {
    .head__video--main {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  .head__video--main {
    > a {
      width: 100%;
    }
    .add__loop {
      margin-right: 0px;
      margin-bottom: 12px;
      width: 100%;
    }
    .sub__channel {
      margin-right: 0px;
      margin-bottom: 12px;
      width: 100%;
    }
  }
  .video__list--container {
    .video__list--wrap {
      grid-template-columns: 1fr;
    }
  }
}
@media (max-width: 400px) {
  .comment__form {
    textarea {
      height: 63px;
    }
  }
  .camera__controls {
    > a {
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;
    }
  }
  .notification__drop {
    min-width: 250px;
    max-width: 250px;
  }
  .elem__card {
    .card__head {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
  }
  .top__plan {
    grid-template-columns: 1fr;
    grid-row-gap: 20px;
  }
}
@media (max-width: 360px) {
  .header__right {
    .small__search {
      margin-right: 5px;
      > a {
        min-width: 32px;
        min-height: 32px;
        max-width: 32px;
        max-height: 32px;
      }
    }
    .notification__icon {
      > a {
        min-width: 32px;
        min-height: 32px;
        max-width: 32px;
        max-height: 32px;
      }
    }
    .points__header {
      > a {
        min-height: 32px;
      }
    }
  }
  .points__header {
    margin-right: 5px;
  }
  .notification__icon {
    margin-right: 5px;
  }
}
