.container {
  margin: 50px 30px 20px 30px;

  .text {
    margin-bottom: 20px;

    text-align: center;
  }

  .controls {
    display: flex;
    justify-content: center;
    column-gap: 45px;

    font-size: 1rem;

    & button {
      font-weight: bold;

      height: 30px;
      width: 60px;
      border-radius: 4px;

      color: #ffffff;

      &.approve {
        background: #41cf90;
      }

      &.cancel {
        background: #cc3334;
      }
    }
  }
}
