.container {
  display: flex;

  cursor: pointer;
  align-items: center;
  border-bottom: solid 1px #000000;

  background: #000000;

  width: inherit;
}

.children {
  display: flex;
  text-align: left;
  color: #ffffff;

  width: calc(100% - 15px);

  margin: 5px;

  align-items: center;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  & > div {
    margin-right: 5px;
  }
}
