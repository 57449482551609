.App {
  text-align: center;
  height: 100%;
}

.trash {
  position: absolute;

  height: 120px;
  width: 80px;

  display: flex;

  flex-direction: column;

  left: 0;
  top: 0;
}
