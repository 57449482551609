.timer {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: #ffffff;

  width: 100px;
  height: 30px;
  padding: 0 12px;
  background: rgba(204, 51, 52, 0.4);

  position: absolute;

  bottom: 146px;
  left: 0;
  right: 0;
  margin: auto;
}

.indicator {
  height: 8px;
  width: 8px;
  border-radius: 4px;
  background: #ffffff;
}

@keyframes blinking {
  0% {
    background: red;
  }
  50% {
    background: #ffffff;
  }
  100% {
    background: red;
  }
}

.rec {
  animation: blinking 2s infinite;
}
