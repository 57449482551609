.container {
  position: fixed;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9999999;

  display: flex;
  justify-content: center;
  align-items: center;

  .content {
    background: #e5e5e5;
    border-radius: 4px;
    max-width: 420px;
  }
}
