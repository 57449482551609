.video_container {
  position: relative;

  height: 100%;
}

.video {
  background: #000000;
}

.controls {
  position: absolute;

  left: calc(50% - 25px);
  display: flex;
  justify-content: center;

  z-index: 9;

  bottom: 90px;

  &.redo {
    right: 20px;
    left: auto;
  }
}
