$borderRadius: 10px;

.container {
  position: relative;

  background: #000000;

  font-weight: 500;
  font-size: 14px;
  line-height: 30px;

  border-radius: $borderRadius;

  cursor: pointer;

  &_open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  &_title {
    background: #000000;

    color: #ffffff;

    border-radius: $borderRadius;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    width: calc(100% - 10px);
    margin: 0 5px;

    position: relative;

    &__carpet {
      &:after {
        content: '';
        position: absolute;
        right: 0;

        top: calc(50% - 5px);

        border: 6px solid transparent;
        border-top: 6px solid #b5bbc5;
      }

      &_open:after {
        border-bottom: 6px solid #b5bbc5;
        border-top: initial;
      }
    }
  }

  &_options {
    max-height: 200px;

    overflow: hidden;
    overflow-y: auto;

    position: absolute;

    width: 100%;

    z-index: 20;

    background: #000000;
    color: #ffffff;

    text-align: center;
  }
}
