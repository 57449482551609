.container {
  position: absolute;

  width: 100%;

  bottom: 0;

  background: rgba(23, 16, 47, 0.3);
  padding: 10px 20px;

  display: flex;
  flex-direction: column;
  row-gap: 10px;

  .first_line {
    display: flex;
    justify-content: center;
    align-items: center;

    .slider {
      width: calc(100% - 10px);

      align-items: center;
      display: flex;

      position: relative;
    }
  }

  .second_line {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    column-gap: 20px;

    position: relative;

    .button {
      &.fullscreen {
        position: absolute;
        right: 0;
      }
    }

    .sound_bar {
      width: 100px;
    }
  }
}
