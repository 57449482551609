@import './assets/scss/index.scss';

body {
  margin: 0;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  min-height: 100vh;
  min-height: -webkit-fill-available;
  display: flex;
}

html {
  height: -webkit-fill-available;
}

#root {
  width: 100%;
}
