.title {
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
}

.description {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
}
