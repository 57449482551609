.container {
  position: relative;

  z-index: 101;

  width: 220px;

  &.loading {
    background-color: #d3ddef;
  }

  .progress_bar {
    position: absolute;
    height: 100%;
    left: 0;

    background-color: #41cf90;
  }

  span {
    z-index: 1;
  }
}
