.container {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: #ffffff;

  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  position: relative;
  margin: 0 5px;

  &:hover,
  &:active {
    box-shadow: 0px 4.62px 4.62px rgba(0, 0, 0, 0.25);
  }

  @media only screen and (max-width: 768px) {
    & {
      margin: 5px 5px;
    }
  }
}

.icon {
  align-items: center;
  display: flex;
  justify-content: center;
}

.setting_block {
  height: 9px;
  width: 9px;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 1px;
  right: 1px;
  background-color: #ffffff;
}

.settings {
}

.name {
  position: absolute;

  min-width: 40px;
  white-space: pre;
  padding: 5px;
  height: 20px;

  background-color: rgba(0, 0, 0, 0.4);

  top: 50px;

  font-weight: 300;
  font-size: 10px;

  color: #ffffff;

  display: flex;
  align-items: center;
  justify-content: center;
}

.fadeIn {
  -webkit-animation-name: fadeIn;
  animation-name: fadeIn;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
