.logo {
  margin: 10px;
  left: 0;
}

.container {
  padding: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  & .logo {
    position: unset;
    filter: grayscale(100%);
  }

  & .upload_btn {
    margin: auto;
  }
}

.warning {
  font-weight: 500;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  text-align: center;

  margin-top: 50px;
  margin-bottom: 34px;
}

@media only screen and (max-width: 768px) {
  .logo {
    max-width: 100px;
    max-height: 60px;
  }

  .warning {
    margin: 10px;
  }

  .container {
    margin: 10px;
  }
}
