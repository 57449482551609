.container {
  display: flex;
  align-items: center;
}

.icon {
  width: 18px;
  height: 18px;
  display: flex;
  cursor: pointer;
  margin-right: 10px;
}

.player_sound_bar {
  width: 100px;
  display: flex;
  align-items: center;
}
