@use "sass:math";

$boxSize: 200px;
$maxWidthHorisontal: 768px;
$maxWidthVertical: 460px;

.container {
  position: relative;

  background-color: initial;
}

.wrapper {
  position: fixed;

  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.box {
  position: absolute;

  background-color: #454545;

  width: $boxSize;

  bottom: 55px;
  left: math.div(-$boxSize, 2) + 24px;

  display: flex;

  flex-direction: column;

  z-index: 999999999999;

  align-items: flex-end;

  @media only screen and (max-width: $maxWidthHorisontal) {
    & {
      top: 55px;
      bottom: initial;
    }
  }

  @media only screen and (max-width: $maxWidthVertical) {
    & {
      left: 55px;
      top: -75px;
    }
  }

  &::after {
    content: '';
    position: absolute;
    right: calc(50% - 10px);

    bottom: -20px;

    border: 10px solid transparent;
    border-top: 10px solid #454545;

    @media only screen and (max-width: $maxWidthHorisontal) {
      & {
        top: -20px;
        bottom: initial;

        transform: rotate(180deg);
      }
    }

    @media only screen and (max-width: $maxWidthVertical) {
      & {
        top: calc(50% - 10px);
        bottom: initial;
        left: -20px;

        transform: rotate(90deg);
        width: 10px;
      }
    }
  }

  &_container {
    margin: 10px auto;
    width: 80%;

    &__title {
      color: #ffffff;
    }

    &__select {
    }
  }

  &_btn {
    background-color: #5665fa;

    color: #ffffff;

    height: 30px;
    width: 50px;

    border-radius: 15px;

    margin: 10px 15px;

    z-index: 10;
  }
}
