.container {
  color: #ffffff;

  .current_time {
    color: inherit;
  }

  .duration {
    color: inherit;
  }
}
