.container {
  position: relative;
  height: 100%;
}

.controls {
  @media only screen and (max-width: 768px) {
    & {
      position: absolute;

      margin: auto;
      left: 0;
      right: 0;
      top: 10px;

      background-color: inherit;
    }
  }

  @media only screen and (max-width: 460px) {
    & {
      left: 10px;
      margin: 0;

      display: flex;
      flex-direction: column;
      row-gap: 10px;
      align-items: flex-start;
    }
  }
}
