.time_out_timer {
  position: absolute;
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  height: 100px;
  width: 100px;
  border-radius: 50%;
}

.time_out_timer.animation {
  background-color: rgba(0, 0, 0, 0.4);
  animation: showing 1s;
}

@media only screen and (max-height: 320px) {
  .time_out_timer {
    margin: 20px auto;
  }
}
